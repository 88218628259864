@import "variables";
//Bootstrap Required
@import "../../Contrib/Bootstrap/scss/functions";
@import "../../Contrib/Bootstrap/scss/variables";
@import "../../Contrib/Bootstrap/scss/mixins";

.ge-image-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .img-col {
        .img-wrapper {
            img {
                width: 100%;
            }
        }
    }
}
@include media-breakpoint-up(xl){
    .ge-image-container {
        .img-col {
            .img-wrapper {
                overflow: hidden;
                height: 100%;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;

                }
            }
        }

        .content-col {
            &.img-left {
                .container-half {
                    margin-right: auto;
                    padding-left: 1rem;
                }
            }

            &.img-right {
                .container-half {
                    margin-left: auto;
                    padding-right: 1rem;
                }
            }
        }
    }
}

@media (min-width: 1400px){
    .ge-image-container {
        .content-col {
            &.img-left {
                .container-half {
                    padding-left: 2.5rem;
                }
            }

            &.img-right {
                .container-half {
                    padding-right: 2.5rem;
                }
            }
        }
    }
}

@media (min-width: 1600px){
    .ge-image-container {
        .content-col {
            &.img-left {
                .container-half {
                    padding-left: 5rem;
                }
            }

            &.img-right {
                .container-half {
                    padding-right: 5rem;
                }
            }
        }
    }
}